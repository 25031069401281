export const socialsData = {
    github: 'https://github.com/NeiderRequene',
    facebook: 'https://www.facebook.com/neider.requene.3',
    linkedIn: 'https://www.linkedin.com/in/neider-requene/',
    instagram: 'https://www.instagram.com/neider_requene/',
    twitter: 'https://twitter.com/RequeneNeider/',
    gitlab: 'https://gitlab.com/NeiderRequene',
    codepen: '',
    reddit: '',
    blogger: '',
    medium: '',
    stackOverflow: '',
    youtube: '',
}