import React, { useContext } from 'react';

import { ThemeContext } from '../../contexts/ThemeContext';

import { servicesData } from '../../data/servicesData';

import './Services.css'
import SingleService from './SingleService/SingleService';

function Services() {

    const { theme } = useContext(ThemeContext);
    return (
        <>
            {servicesData.length > 0 && (
                <div className="services" id="services" style={{ backgroundColor: theme.primary }}>
                    <div className="services-header">
                        <h1 style={{ color: theme.secondary }}>Servicios</h1>
                    </div>
                    <div className="services-body">
                        <p style={{ color: theme.secondary }}>
                            Estos son algunos de los servicios que ofrezco. ¡Comunícate conmigo si puedo ayudarte con algo!
                        </p>
                        <div className="services-bodycontainer">
                            {servicesData.map(services => (
                                <SingleService
                                    key={services.id}
                                    id={services.id}
                                    title={services.title}
                                    icon={services.icon} />
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default Services
