export const achievementData = {
    bio: "El tiempo que se invierte en el crecimiento personal es siempre bien empleado.",
    achievements: [
        {
            id: 1,
            title: 'AWS Certified Cloud Practitioner',
            details: 'Issued by Amazon Web Services Training and Certification',
            date: 'Junio 20, 2024',
            field: '',
            image: 'https://neider-dev.s3.us-east-2.amazonaws.com/portafolio-personal/aws.png'
        },
        {
            id: 2,
            title: '1er Lugar en 1er Concurso Interno de Programación UTN',
            details: '1er Lugar en 1er Concurso Interno de Programación UTN en la categoría de Programación Web y Móvil.',
            date: 'Junio 20, 2018',
            field: '',
            image: 'https://www.universidades.com.ec/logos/original/logo-universidad-tecnica-del-norte.webp'
        },
        {
            id: 3,
            title: '3er Lugar en 1er Concurso de Programación UPS',
            details: '3er Lugar en 1er Concurso de Programación pura en Java Universidad Politécnica Salesiana',
            date: 'Septiembre 30, 2018',
            field: '',
            image: 'https://univercimas.com/wp-content/uploads/2021/04/Universidad-Politecnica-Salesiana.jpg'
        },
        {
            id: 4,
            title: 'Presidente del Club de Programación UTN',
            details: 'Presidente del Club de Programación de la Universidad Técnica del Norte',
            date: 'Junio 26, 2019',
            field: '',
            image: 'https://neider-dev.s3.us-east-2.amazonaws.com/portafolio-personal/305073569_951811412432880_2091521717946528506_n.png'
        },
        {
            id: 5,
            title: 'English B1 Preliminary',
            details: 'Universidad Técnica del Norte',
            date: 'Octubre 10, 2022',
            field: '',
            image: 'https://logodownload.org/wp-content/uploads/2021/04/university-of-cambridge-logo-3.png'
        },

    ]
}


// Do not remove any fields.
// Leave it blank instead as shown below.

/*

export const achievementData = {
    bio : "",
    achievements : []
}

*/