export const projectsData = [
    {
        id: 1,
        projectName: 'Administrador de Auditorium',
        projectDesc: 'Este proyecto tiene como objetivo construir un administrador de los cursos y podcast que ofrece la empresa.',
        tags: ['React', 'CSS', 'Material Ui'],
        code: 'https://auditorium.neiamy.com',
        demo: 'https://auditorium.neiamy.com',
        image: 'https://neider-dev.s3.us-east-2.amazonaws.com/portafolio-personal/AdminAuditorium.png'
    },
    {
        id: 2,
        projectName: 'Administrador del Círculo Militar',
        projectDesc: 'Este proyecto tiene como objetivo construir un administrador de los servicios que ofrece el club.',
        tags: ['React', 'Django', 'Tailwind CSS'],
        code: 'https://www.circulomilitar.services',
        demo: 'https://www.circulomilitar.services',
        image: 'https://neider-dev.s3.us-east-2.amazonaws.com/portafolio-personal/AdminCM.png'
    },
    {
        id: 3,
        projectName: 'One Saludsa',
        projectDesc: 'Este proyecto tiene como objetivo construir una página de los servicios que ofrece el empresa.',
        tags: ['Wordpress', 'CSS', 'Javascript'],
        code: 'https://one.saludsa.com',
        demo: 'https://one.saludsa.com',
        image: 'https://neider-dev.s3.us-east-2.amazonaws.com/portafolio-personal/OneSaludsa.png'
    }
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/