export const educationData = [
    {
        id: 1,
        institution: 'Universidad Técnica del Norte',
        course: 'Ingeniero en Sistemas Computacionales',
        startYear: '2016',
        endYear: '2022'
    },
    {
        id: 2,
        institution: 'Unidad Educativa Fiscomisional "10 de Agosto"',
        course: 'Bachiller Técnico en Servicios de Aplicaciones Informáticas',
        startYear: '2011',
        endYear: '2016'
    }
]