export const experienceData = [
    {
        id: 1,
        company: 'MovigoO',
        jobtitle: 'Full Stack Developer',
        startYear: '2023',
        endYear: 'Actualidad',
        location: "Región Metropolitana de Santiago, Chile - (En remoto)"
    },
    {
        id: 2,
        company: 'Webcreek',
        jobtitle: 'Full Stack Developer',
        startYear: '2022',
        endYear: '2023',
        location:"1800 Hughes Landing Blvd The Woodlands, Texas, Estados Unidos 77380 - (En remoto)"
    },
    {
        id: 3,
        company: 'Bitproy',
        jobtitle: 'Software Developer - Freelancer',
        startYear: '2021',
        endYear: '2022',
        location:"Ibarra, Imbabura, Ecuador - (En remoto)"
    },
    {
        id: 4,
        company: 'TEON, Ibarra',
        jobtitle: 'Frontend Developer',
        startYear: '2020',
        endYear: '2022',
        location:"Ibarra, Imbabura, Ecuador - (En remoto)"
    },
]